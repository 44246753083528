<template>
  <b-tab :title="$t('ordersSessionTabHistory')">
    <div
      v-for="historyItem in historyToRender"
      :key="historyItem.id"
    >
      {{ historyItem.timestampFormatted }} - {{ historyItem.messageFormatted }}
    </div>
  </b-tab>
</template>

<script>
import { BTab } from 'bootstrap-vue'
import mixinFormatter from '@/mixins/formatter'

export default {
  name: 'OrderHistoryTab',
  components: {
    BTab,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    history: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    historyToRender() {
      return this.history
        .map(i => ({
          ...i,
          timestampFormatted: this.getFormattedOrderHistoryTimestamp(i.timestamp),
          messageFormatted: this.getFormattedOrderHistoryMessage(i.message),
        }))
        .reverse()
    },
  },
}
</script>
