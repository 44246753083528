<template>
  <div
    :class="[
      'orders-table',
      {
        'orders-table_selected': table.id === selectedTableId,
        'orders-table_active': table.activeTableOrder,
      },
    ]"
  >
    <div
      v-if="table.activeTableOrder"
      class="orders-table__header"
    >
      <div class="orders-table__users">
        <icon-users />
        {{ table.activeTableOrder.guests }}
      </div>
      <div class="orders-table__actions">
        <icon-database v-if="table.activeTableOrder.isBillActive" />
        <icon-bell v-if="table.activeTableOrder.isCallActive" />
        <icon-spoon-fork v-if="table.isItemsChanged" />
        <!--<icon-wineglass />-->
      </div>
    </div>
    <div class="orders-table__body">
      <h3 class="h3 mb-0 text-center">
        {{ table.name }}
      </h3>
    </div>
    <div
      v-if="table.activeTableOrder"
      class="orders-table__footer"
    >
      <div class="orders-table__price">
        <currency-symbol>{{ getFormattedPrice(orderSubtotal) }}</currency-symbol>
      </div>
    </div>
  </div>
</template>

<script>
import IconDatabase from '@/components/icon/IconDatabase.vue'
import IconBell from '@/components/icon/IconBell.vue'
import IconSpoonFork from '@/components/icon/IconSpoonFork.vue'
// import IconWineglass from '@/components/icon/IconWineglass.vue'
import IconUsers from '@/components/icon/IconUsers.vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import { mapGetters } from 'vuex'
import {
  getOrderSubtotal,
  // getOrderSurcharges,
  // getOrderTotal,
} from '@/order'

export default {
  name: 'OrdersTable',
  components: {
    IconDatabase,
    IconBell,
    IconSpoonFork,
    // IconWineglass,
    IconUsers,
    CurrencySymbol,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    table: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedTableId: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
    orderSubtotal() {
      return getOrderSubtotal(this.table.activeTableOrder.items)
    },
    // orderSurcharges() {
    //   if (this.restaurant && this.restaurant.surcharges) {
    //     return getOrderSurcharges(this.restaurant.surcharges, this.orderSubtotal)
    //   }
    //   return []
    // },
    // ordersTotal() {
    //   return getOrderTotal(this.orderSubtotal, this.orderSurcharges)
    // },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .orders-table
    cursor: pointer
    width: 124px
    height: 124px
    background-color: $white
    border: 1px solid $border-color
    border-radius: 20px
    padding: 12px
    position: relative
    &__header
      position: absolute
      top: 12px
      left: 12px
      right: 12px
      display: flex
      align-items: center
      justify-content: space-between
    &__body
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
    &__footer
      position: absolute
      bottom: 12px
      left: 12px
      right: 12px
      display: flex
      align-items: center
      justify-content: flex-end
    &__users
      display: flex
      align-items: center
      .icon-custom
        margin-right: 4px
    &__actions
      display: flex
      align-items: center
      .icon-custom
        margin-right: 8px
        &:last-child
          margin-right: 0
        path
          fill: $primary

    &_active
      border-color: transparent
      box-shadow: 0 1px 20px rgba(16, 16, 16, 0.06)
      .orders-table
        &__body
          .h3
            color: $primary
            font-weight: $font-weight-bolder
    &_selected
      background-color: $primary
      .orders-table
        &__header
          color: $white
        &__body
          .h3
            color: $white
        &__footer
          color: $white
        &__users
          .icon-custom
            path
              fill: $white
        &__actions
          .icon-custom
            path
              fill: $white
</style>
