import GET_TABLES from '@/gql/query/getTables.gql'

import ORDER_SESSION_STARTED from '@/gql/subscription/order/orderSessionStarted.gql'
import ORDER_UPDATED from '@/gql/subscription/order/orderUpdated.gql'
import ORDER_SESSION_CLOSED from '@/gql/subscription/order/orderSessionClosed.gql'
import ORDER_UPDATE_NOTIFICATION from '@/gql/subscription/order/orderUpdateNotification.gql'

import MAKE_ORDER from '@/gql/mutation/order/makeOrder.gql'
import UPDATE_ORDER from '@/gql/mutation/order/updateOrder.gql'
import REMOVE_ITEM_FROM_ORDER from '@/gql/mutation/order/removeItemFromOrder.gql'

import START_ORDER_SESSION from '@/gql/mutation/order/startOrderSession.gql'
import CLOSE_ORDER_SESSION from '@/gql/mutation/order/closeOrderSession.gql'

export default {
  methods: {
    getTablesQuery(restaurantId, code) {
      return this.$apollo.query({
        query: GET_TABLES,
        variables: {
          restaurantId,
          code,
        },
      })
    },

    orderSessionStartedSubscription(restaurantId) {
      return this.$apollo.subscribe({
        query: ORDER_SESSION_STARTED,
        variables: {
          restaurantId,
        },
      })
    },
    orderUpdatedSubscription(restaurantId) {
      return this.$apollo.subscribe({
        query: ORDER_UPDATED,
        variables: {
          restaurantId,
        },
      })
    },
    orderSessionClosedSubscription(restaurantId) {
      return this.$apollo.subscribe({
        query: ORDER_SESSION_CLOSED,
        variables: {
          restaurantId,
        },
      })
    },
    orderUpdateNotificationSubscription(restaurantId) {
      return this.$apollo.subscribe({
        query: ORDER_UPDATE_NOTIFICATION,
        variables: {
          restaurantId,
        },
      })
    },

    makeOrderMutation({
      restaurantId,
      zoneId,
      tableId,
      code,
      offers,
    }) {
      return this.$apollo.mutate({
        mutation: MAKE_ORDER,
        variables: {
          restaurantId,
          zoneId,
          tableId,
          code,
          offers,
        },
      })
    },
    updateOrderMutation({
      id,
      restaurantId,
      zoneId,
      tableId,
      code,
      offers,
      items,
      isBillActive,
      isCallActive,
      guests,
    }) {
      return this.$apollo.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          id,
          restaurantId,
          zoneId,
          tableId,
          code,
          offers,
          items,
          isBillActive,
          isCallActive,
          guests,
        },
      })
    },
    removeItemFromOrderMutation({ orderId, orderItemIds }) {
      return this.$apollo.mutate({
        mutation: REMOVE_ITEM_FROM_ORDER,
        variables: {
          orderId,
          orderItemIds,
        },
      })
    },

    startOrderSessionMutation(restaurantId, zoneId, tableId, guests) {
      return this.$apollo.mutate({
        mutation: START_ORDER_SESSION,
        variables: {
          restaurantId,
          zoneId,
          tableId,
          guests,
        },
      })
    },
    closeOrderSessionMutation(orderId) {
      return this.$apollo.mutate({
        mutation: CLOSE_ORDER_SESSION,
        variables: {
          orderId,
        },
      })
    },
  },
}
