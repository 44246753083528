<template>
  <svg
    class="icon-custom"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28754 7.50061C9.75886 7.42206 10.2302 7.1864 10.5444 6.79364L12.9795 4.35849C13.2937 4.04428 13.2937 3.57296 12.9795 3.25875L12.901 3.10165L10.5444 5.45823C10.3873 5.61534 10.0731 5.61534 9.83741 5.45823C9.60175 5.30113 9.68031 4.98692 9.83741 4.75126L11.8798 2.70888C12.0369 2.55178 12.0369 2.23756 11.8798 2.0019C11.7227 1.8448 11.4085 1.8448 11.1728 2.0019L9.13043 4.04428C8.97333 4.20139 8.65912 4.20139 8.42346 4.04428C8.26635 3.88718 8.26635 3.57296 8.42346 3.3373L10.78 0.980716L10.7015 0.902163C10.3873 0.587951 9.91596 0.587951 9.60175 0.902163L7.16661 3.49441C6.8524 3.80862 6.61674 4.27994 6.53819 4.75126L2.68909 0.980716C2.37488 0.666504 1.90356 0.745057 1.6679 0.980716L1.51079 1.13782C0.411053 2.23756 0.803818 4.43705 2.68909 6.32232L3.31751 6.95074C3.63173 7.26495 4.02449 7.50061 4.49581 7.57916C4.10304 7.89338 3.86738 8.20759 3.86738 8.20759L1.19658 10.8784C0.646712 11.4283 0.646712 12.3709 1.19658 12.9208C1.74645 13.4706 2.68909 13.4706 3.23896 12.9208L5.83121 10.1714C5.98831 10.0143 6.38108 9.54299 6.8524 8.99312C7.08806 9.30733 7.24516 9.46444 7.24516 9.46444L10.6229 12.8422C11.1728 13.3921 12.1154 13.3921 12.6653 12.8422C13.2152 12.2923 13.2152 11.3497 12.6653 10.7998L9.28754 7.50061Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSpoonFork',
}
</script>
