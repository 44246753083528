<template>
  <b-col cols="4">
    <div class="session">
      <div class="session__header">
        <div class="flex-grow-1">
          <b-row class="d-flex align-items-center">
            <b-col>
              <h5 class="mb-0">
                {{ sessionZoneData.zoneName }}: #{{ sessionTableData.name }}
              </h5>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex"
            >
              <feather-icon
                icon="XIcon"
                size="20"
                role="button"
                @click="onClickCloseSidebar"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="sessionTableData.activeTableOrder"
            class="align-items-center mt-75"
          >
            <b-col>
              <div class="d-flex align-items-center">
                <!--<b-dropdown
                  :text="waiters.find((i) => i.id === selectedWaiterId).name"
                  variant="link"
                  no-caret
                  class="session__dropdown mr-1"
                >
                  <b-dropdown-item
                    v-for="waiter in waiters"
                    :key="waiter.id"
                    :disabled="selectedWaiterId === waiter.id"
                    @click="selectedWaiterId = waiter.id"
                  >
                    {{ waiter.name }}
                  </b-dropdown-item>
                </b-dropdown>-->
                <b-dropdown
                  variant="link"
                  no-caret
                  class="session__dropdown"
                  @hidden="onHiddenDropdownGuests"
                >
                  <template #button-content>
                    <span class="d-flex align-items-center">
                      <icon-users class="mr-25 fill-primary" />
                      {{ sessionTableData.activeTableOrder.guests }}
                    </span>
                  </template>
                  <div class="d-flex justify-content-center">
                    <b-form-spinbutton
                      v-model="guests"
                      size="sm"
                      min="1"
                      max="50"
                      inline
                      :disabled="isGuestsUpdating"
                    />
                  </div>
                </b-dropdown>
                <b-spinner
                  v-if="isGuestsUpdating"
                  variant="primary"
                  class="d-flex ml-50"
                  small
                />
              </div>
            </b-col>
            <b-col cols="auto">
              {{ $t('Order') }} #{{ sessionTableData.activeTableOrder.orderNumber }}
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="session__body">
        <template v-if="sessionTableData.activeTableOrder">
          <b-tabs>
            <b-tab :title="$t('ordersSessionTabMain')">
              <b-button
                class="d-flex justify-content-center"
                variant="outline-primary"
                block
                :disabled="isAddToOrderInProgress || isPanelAdd"
                @click="onClickAddToOrder"
              >
                <b-spinner
                  v-if="isAddToOrderInProgress"
                  small
                />
                <template v-else>
                  {{ $t('ordersAddToOrderButtonName') }}
                </template>
              </b-button>
              <div
                v-if="badges.length"
                class="session__tags"
              >
                <b-badge
                  v-for="badge in badges"
                  :key="badge.id"
                  role="button"
                  :variant="isStatusUpdating ? 'light-secondary' : 'light-primary'"
                  :class="{ 'cursor-default': isStatusUpdating }"
                  @click="onClickBadge(badge.id)"
                >
                  {{ badge.label }}
                  <feather-icon icon="XIcon" />
                </b-badge>
              </div>
              <template v-if="itemsToBeAdded.length">
                <div class="session-heading">
                  <span class="session-heading__value">
                    {{ $t('ordersItemsToBeAddedLabel') }}
                  </span>
                </div>
                <table class="session-table">
                  <tbody class="session-table__tbody">
                    <orders-session-to-be-added-item
                      v-for="item in itemsToBeAdded"
                      :key="item.id"
                      :menus="menus"
                      :item="item"
                      @edit="onEditToBeAddedItem(item.id)"
                      @delete="onDeleteToBeAddedItem(item.id)"
                    />
                  </tbody>
                </table>
                <b-button
                  class="mt-1 d-flex justify-content-center"
                  variant="primary"
                  block
                  :disabled="isOrderSending"
                  @click="onClickSendOrder"
                >
                  <b-spinner
                    v-if="isOrderSending"
                    small
                  />
                  <template v-else>
                    {{ $t('ordersSendOrderButtonName') }}
                  </template>
                </b-button>
                <div class="mt-1" />
              </template>
              <template v-if="sessionTableData.activeTableOrder && orderedItemsToRender.length">
                <div class="session-heading">
                  <span class="session-heading__value">
                    {{ $t('ordersCurrentOrderLabel') }}
                  </span>
                </div>
                <table class="session-table">
                  <tbody class="session-table__tbody">
                    <orders-session-added-item
                      v-for="item in orderedItemsToRender"
                      :key="item.id"
                      :session-item="item"
                      :is-actions="true"
                      @noCharge="onNoChargeAddedItem(item)"
                      @delete="onDeleteAddedItem(item)"
                    />
                  </tbody>
                </table>
              </template>
            </b-tab>
            <order-history-tab :history="sessionTableData.activeTableOrder.history" />
          </b-tabs>
        </template>
        <template v-else>
          <div class="session__guests-counter">
            <div class="session__guests">
              <icon-users class="mr-50" />
              {{ $t('ordersGuestsLabel') }}
            </div>
            <b-form-spinbutton
              v-model="guests"
              size="sm"
              min="1"
              max="50"
              inline
              :disabled="isSessionStarting"
            />
          </div>
          <b-button
            class="d-flex justify-content-center"
            variant="primary"
            block
            :disabled="isSessionStarting"
            @click="onClickStartSession"
          >
            <b-spinner
              v-if="isSessionStarting"
              small
            />
            <template v-else>
              {{ $t('ordersStartSessionButtonName') }}
            </template>
          </b-button>
        </template>
      </div>
      <div
        v-if="sessionTableData.activeTableOrder"
        class="session__footer"
      >
        <div class="session-total">
          <div class="session-total__row">
            <div class="session-total__name">
              {{ $t('ordersSubtotalLabel') }}:
            </div>
            <div class="session-total__value">
              <currency-symbol>{{ getFormattedPrice(orderSubtotal) }}</currency-symbol>
            </div>
          </div>
          <!--<div
            v-for="surcharge in orderSurcharges"
            :key="surcharge.id"
            class="session-total__row"
          >
            <div class="session-total__name">
              {{ surcharge.name }}<span
                v-if="surcharge.type === 'PERCENTAGE' || surcharge.type === 'PERCENTAGE_DISCOUNT'"
              > ({{ surcharge.value }}%)</span>:
            </div>
            <div class="session-total__value">
              <currency-symbol>{{ getFormattedPrice(surcharge.renderValue) }}</currency-symbol>
            </div>
          </div>
          <div class="session-total__row">
            <div class="session-total__name">
              {{ $t('ordersTotalLabel') }}:
            </div>
            <div class="session-total__value">
              <currency-symbol>{{ getFormattedPrice(ordersTotal) }}</currency-symbol>
            </div>
          </div>-->
        </div>
        <b-button
          variant="primary"
          block
          @click="isModalEndSession = true"
        >
          {{ $t('ordersEndSessionButtonName') }}
        </b-button>
      </div>
    </div>
    <b-modal
      v-model="isModalEndSession"
      centered
      hide-header
      hide-footer
    >
      <div class="py-2">
        <h3 class="text-center mb-2">
          {{ $t('ordersEndSessionMessage') }}
        </h3>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="isSessionEnding"
            @click="onClickEndSession"
          >
            <b-spinner
              v-if="isSessionEnding"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('ordersEndSessionConfirm') }}
            </template>
          </b-button>
          <b-button
            :disabled="isSessionEnding"
            variant="outline-primary"
            @click="isModalEndSession = false"
          >
            {{ $t('ordersEndSessionDecline') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="isModalNoCharge"
      centered
      hide-header
      hide-footer
    >
      <div class="py-2">
        <h3 class="text-center mb-2">
          {{ $t('ordersNoChargeMessage') }}
        </h3>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="isNoChargeInProgress"
            @click="onClickNoCharge"
          >
            <b-spinner
              v-if="isNoChargeInProgress"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('ordersNoChargeConfirm') }}
            </template>
          </b-button>
          <b-button
            :disabled="isNoChargeInProgress"
            variant="outline-primary"
            @click="isModalNoCharge = false"
          >
            {{ $t('ordersNoChargeDecline') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="isModalDeleteItem"
      centered
      hide-header
      hide-footer
    >
      <div class="py-2">
        <h3 class="text-center mb-2">
          {{ $t('ordersDeleteItemMessage') }}
        </h3>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="isDeleteItemInProgress"
            @click="onClickDeleteItem"
          >
            <b-spinner
              v-if="isDeleteItemInProgress"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('ordersDeleteItemConfirm') }}
            </template>
          </b-button>
          <b-button
            :disabled="isDeleteItemInProgress"
            variant="outline-primary"
            @click="isModalDeleteItem = false"
          >
            {{ $t('ordersDeleteItemDecline') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BDropdown,
  // BDropdownItem,
  BFormSpinbutton,
  BButton,
  BBadge,
  BModal,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import OrdersSessionToBeAddedItem from '@/components/orders/OrdersSessionToBeAddedItem.vue'
import OrdersSessionAddedItem from '@/components/orders/OrdersSessionAddedItem.vue'
import OrderHistoryTab from '@/components/orders/OrderHistoryTab.vue'
import IconUsers from '@/components/icon/IconUsers.vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinGqlOrder from '@/mixins/gql/order'
import mixinFormatter from '@/mixins/formatter'
import { mapGetters, mapActions } from 'vuex'
import {
  stringifyOrderItem,
  getOrderSubtotal,
  // getOrderSurcharges,
  // getOrderTotal,
} from '@/order'

import GET_PUBLISHED_MENUS from '@/gql/query/getPublishedMenus.gql'

export default {
  name: 'OrdersSession',
  components: {
    BRow,
    BCol,
    BDropdown,
    // BDropdownItem,
    BFormSpinbutton,
    BButton,
    BBadge,
    BModal,
    BSpinner,
    BTabs,
    BTab,
    OrdersSessionToBeAddedItem,
    OrdersSessionAddedItem,
    OrderHistoryTab,
    IconUsers,
    CurrencySymbol,
  },
  mixins: [
    mixinGqlOrder,
    mixinFormatter,
  ],
  props: {
    getTables: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedTableId: {
      type: String,
      required: true,
      default: '',
    },
    isPanelAdd: {
      type: Boolean,
      required: true,
      default: false,
    },
    addToOrderItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    updateAddToOrderItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    editToBeAddedItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      // waiters: [
      //   {
      //     id: 1,
      //     name: 'Vitalii Horshechnyk',
      //   },
      //   {
      //     id: 2,
      //     name: 'Gennady Shpiler',
      //   },
      //   {
      //     id: 3,
      //     name: 'Mykyta Onishchenko',
      //   },
      // ],
      // selectedWaiterId: 1,
      guests: 1,
      isSessionStarting: false,
      isAddToOrderInProgress: false,
      itemsToBeAdded: [],
      menus: [],

      isOrderSending: false,
      isStatusUpdating: false,
      isGuestsUpdating: false,

      isModalEndSession: false,
      isSessionEnding: false,

      itemNoCharge: null,
      isModalNoCharge: false,
      isNoChargeInProgress: false,

      itemDelete: null,
      isModalDeleteItem: false,
      isDeleteItemInProgress: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
    badges() {
      return [
        {
          id: 'isBillActive',
          label: this.$t('ordersIsBillActiveBadgeLabel'),
          active: this.sessionTableData.activeTableOrder.isBillActive,
        },
        {
          id: 'isCallActive',
          label: this.$t('ordersIsCallActiveBadgeLabel'),
          active: this.sessionTableData.activeTableOrder.isCallActive,
        },
      ].filter(i => i.active)
    },
    sessionZoneData() {
      return this.getTables.find(i => i.tables.find(j => j.id === this.selectedTableId))
    },
    sessionTableData() {
      return this.sessionZoneData.tables.find(i => i.id === this.selectedTableId)
    },
    orderSubtotal() {
      return getOrderSubtotal(this.sessionTableData.activeTableOrder.items)
    },
    // orderSurcharges() {
    //   if (this.restaurant && this.restaurant.surcharges) {
    //     return getOrderSurcharges(this.restaurant.surcharges, this.orderSubtotal)
    //   }
    //   return []
    // },
    // ordersTotal() {
    //   return getOrderTotal(this.orderSubtotal, this.orderSurcharges)
    // },
    orderedItemsToRender() {
      const items = this.sessionTableData.activeTableOrder.items
        .map(i => [stringifyOrderItem(i, i.item.id), i])
      const itemsWithQuantity = items
        .map(i => [i[0], { ...i[1], quantity: items.map(j => j[0]).filter(j => j === i[0]).length }])
      return [...new Map(itemsWithQuantity).values()]
    },
  },
  watch: {
    selectedTableId: {
      handler() {
        this.guests = this.sessionTableData.activeTableOrder ? this.sessionTableData.activeTableOrder.guests : 1
      },
      immediate: true,
    },
    addToOrderItem(newValue) {
      const toCompare = stringifyOrderItem(newValue)

      const exist = this.itemsToBeAdded.find(i => toCompare === stringifyOrderItem(i))
      if (exist) {
        this.itemsToBeAdded = this.itemsToBeAdded
          .map(i => (i.id === exist.id ? { ...i, quantity: i.quantity + newValue.quantity } : i))
      } else {
        // eslint-disable-next-line no-undef
        this.itemsToBeAdded = [structuredClone(newValue), ...this.itemsToBeAdded]
      }
    },
    updateAddToOrderItem(newValue) {
      const toCompare = stringifyOrderItem(newValue)

      const exist = this.itemsToBeAdded
        .filter(i => i.id !== newValue.id)
        .find(i => toCompare === stringifyOrderItem(i))
      if (exist) {
        this.itemsToBeAdded = this.itemsToBeAdded
          .filter(i => i.id !== newValue.id)
          .map(i => (i.id === exist.id ? { ...i, quantity: i.quantity + newValue.quantity } : i))
      } else {
        // eslint-disable-next-line no-undef
        this.itemsToBeAdded = this.itemsToBeAdded.map(i => (i.id === newValue.id ? structuredClone(newValue) : i))
      }
    },
  },
  methods: {
    ...mapActions({
      shortenPmenusRequest: 'main/shortenPmenusRequest',
    }),
    async onClickBadge(param) {
      if (this.isStatusUpdating) {
        return
      }

      this.isStatusUpdating = true

      await this.updateOrderMutation({
        id: this.sessionTableData.activeTableOrder.id,
        [param]: false,
      })

      this.isStatusUpdating = false
    },
    async onClickSendOrder() {
      this.isOrderSending = true
      this.$emit('closePanelAdd')

      await this.makeOrderMutation({
        restaurantId: this.restaurant.id,
        zoneId: this.sessionZoneData.zoneId,
        tableId: this.sessionTableData.id,

        // eslint-disable-next-line arrow-body-style
        offers: this.itemsToBeAdded.flatMap(i => {
          return Array(i.quantity)
            .fill()
            .map(() => ({
              offerId: i.offerId,
              options: i.options,
              extras: i.extras,
              note: i.note,
            }))
        }),
      })
      this.itemsToBeAdded = []

      this.isOrderSending = false
    },
    onClickCloseSidebar() {
      this.$emit('closeSidebar')
    },
    async onClickStartSession() {
      this.isSessionStarting = true
      await this.startOrderSessionMutation(
        this.restaurant.id,
        this.sessionZoneData.zoneId,
        this.sessionTableData.id,
        this.guests,
      )
      this.isSessionStarting = false
    },
    async onClickAddToOrder() {
      this.isAddToOrderInProgress = true

      const pMenusCode = await this.shortenPmenusRequest([
        this.restaurant.id,
        this.sessionZoneData.zoneId,
        this.sessionTableData.id,
      ])
      // console.log(pMenusCode)
      const response = await this.$apollo.query({
        query: GET_PUBLISHED_MENUS,
        variables: {
          code: pMenusCode,
        },
      })
      this.menus = response.data.getPublishedMenus
      this.$emit('addToOrder', this.menus)

      this.isAddToOrderInProgress = false
    },
    onEditToBeAddedItem(id) {
      this.$emit('editToBeAddedItem', this.itemsToBeAdded.find(i => i.id === id))
    },
    onDeleteToBeAddedItem(id) {
      if (this.editToBeAddedItem.id && this.editToBeAddedItem.id === id) {
        this.$emit('closePanelAdd')
      }
      this.itemsToBeAdded = this.itemsToBeAdded.filter(i => i.id !== id)
    },
    async onHiddenDropdownGuests() {
      if (this.sessionTableData.activeTableOrder.guests === this.guests) {
        return
      }

      this.isGuestsUpdating = true
      await this.updateOrderMutation({
        id: this.sessionTableData.activeTableOrder.id,
        guests: this.guests,
      })
      this.isGuestsUpdating = false
    },

    async onClickEndSession() {
      this.isSessionEnding = true
      await this.closeOrderSessionMutation(this.sessionTableData.activeTableOrder.id)
      this.isSessionEnding = false

      this.isModalEndSession = false
      this.$emit('endSession')
    },
    onNoChargeAddedItem(item) {
      this.itemNoCharge = item
      this.isModalNoCharge = true
    },
    async onClickNoCharge() {
      this.isNoChargeInProgress = true

      const toCompare = stringifyOrderItem(this.itemNoCharge, this.itemNoCharge.item.id)
      await this.updateOrderMutation({
        id: this.sessionTableData.activeTableOrder.id,
        items: this.sessionTableData.activeTableOrder.items
          .map(i => [stringifyOrderItem(i, i.item.id), i])
          .filter(i => i[0] === toCompare)
          .map(i => ({ id: i[1].id, price: 0 })),
      })

      this.isNoChargeInProgress = false

      this.itemNoCharge = null
      this.isModalNoCharge = false
    },
    onDeleteAddedItem(item) {
      this.itemDelete = item
      this.isModalDeleteItem = true
    },
    async onClickDeleteItem() {
      this.isDeleteItemInProgress = true

      const toCompare = stringifyOrderItem(this.itemDelete, this.itemDelete.item.id)
      await this.removeItemFromOrderMutation({
        orderId: this.sessionTableData.activeTableOrder.id,
        orderItemIds: this.sessionTableData.activeTableOrder.items
          .map(i => [stringifyOrderItem(i, i.item.id), i])
          .filter(i => i[0] === toCompare)
          .map(i => i[1].id),
      })

      this.isDeleteItemInProgress = false

      this.itemDelete = null
      this.isModalDeleteItem = false
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/orders-session.sass'
</style>
