<template>
  <div>
    <b-row>
      <b-col>
        <orders-add
          v-if="isPanelAdd"
          :is-panel-add-complex="isPanelAddComplex"
          :is-panel-add-edit-mode="isPanelAddEditMode"
          :menus="menus"
          :edit-to-be-added-item="editToBeAddedItem"
          @close="onClosePanelAdd"
          @updatePanelEditMode="onUpdatePanelEditMode"
          @updateComplexPanel="onUpdateComplexPanel"
          @addToOrderItem="onAddToOrderItem"
          @updateAddToOrderItem="onUpdateAddToOrderItem"
          @clearEditToBeAddedItem="onClearEditToBeAddedItem"
        />
        <orders-zones
          v-else
          :is-setup-in-progress="isSetupInProgress"
          :get-tables="getTables"
          :selected-table-id="selectedTableId"
          @onTableClick="onTableClick"
        />
      </b-col>
      <orders-session
        v-if="selectedTableId"
        :get-tables="getTables"
        :selected-table-id="selectedTableId"
        :is-panel-add="isPanelAdd"
        :add-to-order-item="addToOrderItem"
        :update-add-to-order-item="updateAddToOrderItem"
        :edit-to-be-added-item="editToBeAddedItem"
        @closeSidebar="onCloseSidebar"
        @closePanelAdd="onClosePanelAdd"
        @endSession="onEndSession"
        @addToOrder="onAddToOrder"
        @editToBeAddedItem="onEditToBeAddedItem"
      />
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import OrdersAdd from '@/components/orders/OrdersAdd.vue'
import OrdersZones from '@/components/orders/OrdersZones.vue'
import OrdersSession from '@/components/orders/OrdersSession.vue'
import mixinGqlOrder from '@/mixins/gql/order'
import mixinFormatter from '@/mixins/formatter'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OrdersLocal',
  components: {
    BRow,
    BCol,
    OrdersAdd,
    OrdersZones,
    OrdersSession,
  },
  mixins: [
    mixinGqlOrder,
    mixinFormatter,
  ],
  data() {
    return {
      isPanelAdd: false,
      isPanelAddComplex: false,
      isPanelAddEditMode: false,
      getTables: [],
      selectedTableId: '',
      isSetupInProgress: true,
      menus: [],
      addToOrderItem: {},
      editToBeAddedItem: {},
      updateAddToOrderItem: {},
      notifications: [],
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      isMenuItemsFetched: 'menuManagement/isMenuItemsFetched',
    }),
  },
  watch: {
    restaurant() {
      this.setup()
    },
  },
  created() {
    this.setup()
  },
  methods: {
    ...mapActions({
      getMenuItems: 'menuManagement/getMenuItems',
    }),
    onUpdatePanelEditMode(value) {
      this.isPanelAddEditMode = value
    },
    onUpdateComplexPanel(value) {
      this.isPanelAddComplex = value
    },
    onTableClick(tableId) {
      this.selectedTableId = tableId
      this.getTables = this.getTables.map(i => ({
        ...i,
        tables: i.tables
          .map(j => (j.id === tableId ? { ...j, isItemsChanged: false } : j)),
      }))
    },
    onClosePanelAdd() {
      this.isPanelAddEditMode = false
      this.isPanelAddComplex = false
      this.isPanelAdd = false

      this.editToBeAddedItem = {}
    },
    onCloseSidebar() {
      this.isPanelAddEditMode = false
      this.isPanelAddComplex = false
      this.isPanelAdd = false

      this.selectedTableId = ''
      this.editToBeAddedItem = {}
    },
    onEndSession() {
      this.onCloseSidebar()
    },
    onAddToOrder(event) {
      this.menus = event

      this.isPanelAddEditMode = false
      this.isPanelAddComplex = false
      this.isPanelAdd = true
    },
    onAddToOrderItem(event) {
      this.addToOrderItem = event
    },
    onUpdateAddToOrderItem(event) {
      this.updateAddToOrderItem = event
    },
    onEditToBeAddedItem(event) {
      this.isPanelAddEditMode = true
      this.isPanelAdd = true
      this.editToBeAddedItem = event
    },
    onClearEditToBeAddedItem() {
      this.editToBeAddedItem = {}
    },

    tableItemsChanged(orderId) {
      this.getTables = this.getTables.map(i => ({
        ...i,
        tables: i.tables
          .map(j => (j.activeTableOrder && j.activeTableOrder.id === orderId && this.selectedTableId !== j.id
            ? { ...j, isItemsChanged: true }
            : j
          )),
      }))
    },
    handleNotification(notification) {
      if (notification.source === 'APP') {
        if (notification.message === 'ORDER_UPDATE_ADD' || notification.message === 'ORDER_UPDATE_REMOVE') {
          this.tableItemsChanged(notification.orderId)
        }

        let title = `${notification.zoneName.split(' ').map(i => i[0]).join('')}${notification.tableName}`
        title = `${title}, ${this.getFormattedOrderHistoryMessage(notification.message)}`

        const toastId = this.$toast({
          component: ToastificationContent,
          props: {
            title,
            text: this.getFormattedOrderHistoryTimestamp(notification.timestamp),
            icon: 'CheckCircleIcon',
            variant: 'warning',
          },
        }, {
          timeout: false,
          closeOnClick: true,
          onClick: () => {
            const notificationToHide = this.notifications.filter(i => i.tableId === notification.tableId)
            notificationToHide.forEach(i => this.$toast.dismiss(i.id))

            this.notifications = this.notifications.filter(i => i.tableId !== notification.tableId)

            this.selectedTableId = notification.tableId
          },
        })
        this.notifications = [...this.notifications, { id: toastId, tableId: notification.tableId }]
      }
    },
    updateOrderActiveTable(order, isSessionClosed) {
      this.getTables = this.getTables.map(i => {
        if (i.zoneId === order.zone.id) {
          return {
            ...i,
            tables: i.tables.map(j => {
              if (j.id === order.tableId) {
                return {
                  ...j,
                  activeTableOrder: isSessionClosed
                    ? null
                    // eslint-disable-next-line no-undef
                    : structuredClone(order),
                }
              }
              return j
            }),
          }
        }
        return i
      })

      if (isSessionClosed && this.selectedTableId === order.tableId) {
        this.onCloseSidebar()
      }
    },
    setupSubscriptions() {
      // this is undefined inside 'subscribe'
      const that = this

      const orderSessionStartedObserver = this.orderSessionStartedSubscription(this.restaurant.id)
      orderSessionStartedObserver.subscribe({
        next(data) {
          // console.log('orderSessionStarted', data)
          that.updateOrderActiveTable(data.data.orderSessionStarted, false)
        },
        // error(error) {
        //   console.error(error)
        // },
      })

      const orderUpdatedObserver = this.orderUpdatedSubscription(this.restaurant.id)
      orderUpdatedObserver.subscribe({
        next(data) {
          // console.log('orderUpdated', data)
          that.updateOrderActiveTable(data.data.orderUpdated, false)
        },
        // error(error) {
        //   console.error(error)
        // },
      })

      const orderSessionClosedObserver = this.orderSessionClosedSubscription(this.restaurant.id)
      orderSessionClosedObserver.subscribe({
        next(data) {
          // console.log('orderSessionClosed', data)
          that.updateOrderActiveTable(data.data.orderSessionClosed, true)
        },
        // error(error) {
        //   console.error(error)
        // },
      })

      const orderUpdateNotificationObserver = this.orderUpdateNotificationSubscription(this.restaurant.id)
      orderUpdateNotificationObserver.subscribe({
        next(data) {
          // console.log('orderUpdateNotification', data)
          that.handleNotification(data.data.orderUpdateNotification)
        },
        // error(error) {
        //   console.error(error)
        // },
      })
    },
    async getTablesMethod() {
      const response = await this.getTablesQuery(this.restaurant.id)
      // eslint-disable-next-line no-undef
      this.getTables = structuredClone(response.data.getTables)
      this.getTables = response.data.getTables.map(i => ({
        ...i,
        tables: i.tables.map(j => ({ ...j, isItemsChanged: false })),
      }))
    },
    async setup() {
      if (!this.restaurant) {
        return
      }

      if (!this.restaurant.ordersSettings.enabled) {
        this.$router.push({ name: 'dashboard' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('notificationErrorNotAvailableAddon'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.setupSubscriptions()

      let getActions = ['getTablesMethod']
      if (!this.isMenuItemsFetched) {
        getActions = [...getActions, 'getMenuItems']
      }
      await Promise.all(getActions.map(getAction => this[getAction]()))

      this.isSetupInProgress = false
    },
  },
}
</script>
